/* eslint-disable @next/next/no-img-element */
/* eslint-disable @next/next/no-page-custom-font */
import theme from '@wearesection/bandai-react-components/theme';
import {CacheProvider, EmotionCache} from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import {AppProps} from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Script from 'next/script';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {PageSettingProvider} from '~/providers/page-context.provider';
import createEmotionCache from '~/theme/createEmotionCache';
import '../styles/globals.css';
import 'moment/locale/en-gb';
import 'moment/locale/ko';
import 'moment/locale/zh-tw';
import 'moment/locale/zh-hk';
import 'swiper/css';
import SubscribePopup from '~/components/wrapper/organisms/subscribe-popup';
import {Box} from '@mui/material';
import {wrapper} from '~/redux-toolkit/store';
import {useDispatch} from 'react-redux';
import {getCookie, setCookie} from 'cookies-next';
import {addListCart} from '~/redux-toolkit/cartSlice';
import {
  FOR_STORE,
  GetRouter,
  handSaveCartDataToStore,
  handleConvertToLocaleContentful,
} from '~/utils';
import cartApi from '~/pages/api/services/cart-api';
import {TITLE_LOCAL_FOR_USER} from '~/local-storage';
import LocalStorage from '~/local-storage/local-storage';
import {getGloablByType} from '~/utils/global-operations';
import MaintenancePage from '~/components/wrapper/organisms/maintenance-page/maintenance-page';
import countryApi from './api/services/country-api';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const NavigationBar = dynamic(() => import('~/components/wrapper/organisms/navigation-bar'));
const StoreNavigationBar = dynamic(
  () => import('~/components/wrapper/organisms/store-navigation-bar')
);

const Footer = dynamic(() => import('~/components/wrapper/organisms/footer'));

function MyApp(props: MyAppProps) {
  const router = GetRouter();
  const {locale, route, pathname, asPath} = router;
  const {Component, emotionCache = clientSideEmotionCache, pageProps} = props;
  const listSlugsTranparentTheme: any = {'/games/[slug]': '/games/[slug]'};
  const [isStop, setIsStop] = useState<boolean>(false);
  const [dataStop, setDataStop] = useState<any>({});

  const refTimeOut = useRef<any>(undefined);

  const handleGetStopStore = async (lc: string) => {
    const data = await getGloablByType(
      `${handleConvertToLocaleContentful(lc as string)}`,
      'StopStore'
    );
    if (FOR_STORE.includes(pathname) || FOR_STORE.includes(asPath)) {
      setIsStop(data?.stop || false);
    }
    setDataStop(data);
  };

  useEffect(() => {
    if (locale && window) {
      handleGetStopStore(locale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const isLogged = (getCookie('isLoginSuccess') as string) ?? '';
  // const dataUser = useSelector((state: any) => state?.user?.dataUser);
  const dispatch = useDispatch();

  const adapter = (data: any) => {
    const result = {
      ...data,
      theme: listSlugsTranparentTheme[route] ? 'transparent' : data?.theme,
    };
    return result;
  };

  const renderNaviThemeMemo = useMemo(
    () => adapter(pageProps?.data?.pageCollection?.items?.[0]?.navibar?.configuration),
    [pageProps?.data?.pageCollection?.items?.[0]?.navibar?.configuration]
  );

  //currency transfer without specifying currentToken and timeZone in taiwan
  if (`${locale}`.toLowerCase() === 'tc' && !getCookie('currentToken')) {
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const taiwanTimeZones = [
      'Asia/Taipei',
      'Asia/Kaohsiung',
      'Asia/Tainan',
      'Asia/Chungli',
      'Asia/Hsinchu',
      'Asia/Keelung',
      'Asia/Taichung',
      'Asia/Yilan',
      'Asia/Magong',
      'Asia/Taitung',
    ];
    for (let index = 0; index < taiwanTimeZones.length; index++) {
      const element = taiwanTimeZones[index];
      if (`${element}`.toLowerCase() === `${currentTimeZone}`.toLowerCase()) {
        setCookie('currentToken', 'TWD');
        break;
      }
    }
  }

  useEffect(() => {
    if (window) {
      if (refTimeOut.current) {
        window.clearTimeout(refTimeOut.current);
      }
      refTimeOut.current = setTimeout(() => {
        refTimeOut.current = undefined;
        const current_country = getCookie('current_country');
        //console.log('current_country----1', current_country, asPath);
        if (!current_country && !`${asPath}`.toLowerCase().includes('store')) {
          handleRedirectURLFistLoad();
        }
      }, 500);

      const handleRedirectURLFistLoad = async () => {
        try {
          const countryData = await countryApi.getIP(
            'https://geolocation-db.com/json/f2e84010-e1e9-11ed-b2f8-6b70106be3c8'
          );
          const country = (countryData?.data?.country_code || '').toUpperCase();
          //console.log('country----1', country);
          setCookie('current_country', country, {maxAge: 60 * 60 * 24});
          if (country === 'CN') {
            //console.log('=====2', country);
            window.location.href = `${process.env.NEXT_PUBLIC_SITE_URL}/hk-sc${asPath}`;
            return;
          }
          if (country === 'HK') {
            //console.log('=====3', country);

            window.location.href = `${process.env.NEXT_PUBLIC_SITE_URL}/hk-tc/${asPath}`;
            return;
          }
          if (country === 'TW') {
            //console.log('=====4', country);
            window.location.href = `${process.env.NEXT_PUBLIC_SITE_URL}/tw-tc/${asPath}`;
            return;
          }
          //console.log('=====5', country);
          return;
        } catch (error) {
          console.log('handleRedirectURLFistLoad', error);
        }
      };

      const newLocale = `${locale}`.toLowerCase();
      if (newLocale === 'tc' && !(FOR_STORE.includes(pathname) || FOR_STORE.includes(asPath))) {
        const currentToken = getCookie('currentToken');
        if (currentToken === 'TWD') {
          window.location.href = `${process.env.NEXT_PUBLIC_SITE_URL}/tw-tc/${asPath}`;
        }
        if (currentToken === 'HKD') {
          window.location.href = `${process.env.NEXT_PUBLIC_SITE_URL}/hk-tc/${asPath}`;
        }
        return;
      }
      if (
        ['hk-tc', 'hk-sc', 'tw-tc', 'tw-sc'].includes(newLocale) &&
        (FOR_STORE.includes(pathname) || FOR_STORE.includes(asPath))
      ) {
        if (['hk-tc', 'hk-sc'].includes(newLocale)) {
          setCookie('currentToken', 'HKD');
        }
        if (['tw-tc', 'tw-sc'].includes(newLocale)) {
          setCookie('currentToken', 'TWD');
        }
        window.location.href = `${process.env.NEXT_PUBLIC_SITE_URL}/tc/${asPath}`;
        return;
      }

      if (
        ['hk-tc', 'hk-sc', 'tw-tc', 'tw-sc'].includes(newLocale) &&
        !(FOR_STORE.includes(pathname) || FOR_STORE.includes(asPath))
      ) {
        if (['hk-tc', 'hk-sc'].includes(newLocale)) {
          setCookie('currentToken', 'HKD');
        }
        if (['tw-tc', 'tw-sc'].includes(newLocale)) {
          setCookie('currentToken', 'TWD');
        }
      }
    }
  }, [locale, pathname, asPath]);

  useEffect(() => {
    let timeOut: any = null;
    if (typeof window !== 'undefined') {
      timeOut = setInterval(() => {
        const formCookei = document.getElementById('onetrust-consent-sdk');
        if (formCookei) {
          formCookei.style.pointerEvents = 'auto';
          window.clearInterval(timeOut);
        }
      }, 300);
    }

    if (typeof window !== 'undefined') {
      const current_locale = localStorage.getItem('current_locale') || '';
      const newLocale = `${locale}`.toLowerCase();

      // Get cart
      cartApi
        .getCart(Math.random())
        .then(response => {
          if (response.status !== 200) {
            throw response;
          }
          const data = handSaveCartDataToStore(response?.data?.data?.data);
          localStorage.setItem('current_locale', newLocale);

          if (data.length) {
            if (
              ['hk-tc', 'hk-sc', 'tw-tc', 'tw-sc', 'tc'].includes(newLocale) &&
              ['hk-tc', 'hk-sc', 'tw-tc', 'tw-sc', 'tc'].includes(current_locale)
            ) {
              dispatch(addListCart(data) ?? []);
              return;
            }
            if (
              ['default', 'sea'].includes(newLocale) &&
              ['default', 'sea'].includes(current_locale)
            ) {
              dispatch(addListCart(data) ?? []);
              return;
            }
            // remove cart when different locale
            if (newLocale !== current_locale) {
              cartApi
                .deleteCart()
                .then(() => {
                  dispatch(addListCart([]));
                })
                .catch(err => {
                  console.log(err);
                });
              return;
            }
          }
          dispatch(addListCart(data) ?? []);
        })
        .catch(error => {
          dispatch(addListCart([]));
          console.log(error);
        });
      return;
    }

    // if (dataUser?.id) {
    //   userApi.updateProfile(dataUser?.id, token).then((res: any) => {
    //     if (res?.status !== 200) {
    //       throw res;
    //     }
    //     const data = res?.data;
    //     const newData: any = {
    //       ...data,
    //       addresses: {
    //         data: handleSortAddress(data?.addresses?.data || []),
    //       },
    //     };
    //     // dispatch(saveDataUser(newData));
    //   });
    // }

    // update
    return () => {
      window.clearInterval(timeOut);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, isLogged]);

  useEffect(() => {
    if (!isLogged) {
      const titleList = Object.keys(TITLE_LOCAL_FOR_USER);
      titleList.map(title => {
        LocalStorage.removeItem(title);
      });
    }
  }, [isLogged]);

  const handleShowSubscribe = () => {
    if (
      pathname === '/[[...slug]]' ||
      pathname === '/news' ||
      pathname === '/news/[slug]' ||
      pathname === '/games'
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isShowNavigatorStore = useMemo(() => {
    if (
      route?.includes('/store') ||
      route?.includes('/cart') ||
      route?.includes('/login') ||
      route?.includes('/register') ||
      route?.includes('/account') ||
      route?.includes('/forgot-password')
    ) {
      return true;
    }
    return false;
  }, [route]);

  const contentPage = useMemo(() => {
    return (
      <PageSettingProvider>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <Box
          sx={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {isShowNavigatorStore ? (
            <StoreNavigationBar {...renderNaviThemeMemo} />
          ) : (
            <NavigationBar {...renderNaviThemeMemo} />
          )}
          <CssBaseline />
          {isStop ? (
            <MaintenancePage data={dataStop} locale={locale} />
          ) : (
            <Component {...pageProps} />
          )}

          {handleShowSubscribe() && <SubscribePopup />}
          <Footer {...pageProps?.data?.pageCollection?.items?.[0]?.footer?.configuration} />
        </Box>
      </PageSettingProvider>
    );
  }, [route, isStop, dataStop]);

  return (
    <>
      {/* OneTrust Cookies Consent Notice start for bandai-web-revamp.vercel.app */}
      {
        <Script
          src="https://cdn-apac.onetrust.com/scripttemplates/otSDKStub.js"
          data-document-language="true"
          type="text/javascript"
          charSet="UTF-8"
          data-domain-script="e44e50cd-1032-4426-a8a0-0304ff8a035b-test"
        />
      }
      {
        <Script
          id="optanon-wrapper"
          dangerouslySetInnerHTML={{
            __html: `
            function OptanonWrapper() { }
            `,
          }}
        />
      }
      {/* <!-- OneTrust Cookies Consent Notice end for bandai-web-revamp.vercel.app --> */}

      {/* Google Analytics 1 */}
      {process.env.NEXT_PUBLIC_ENV === 'production' && (
        <Script async src="https://www.googletagmanager.com/gtag/js?id=G-60Q3YX4N45" />
      )}
      {process.env.NEXT_PUBLIC_ENV === 'production' && (
        <Script
          id="ga-config-1"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-60Q3YX4N45');
              `,
          }}
        />
      )}

      {/* Google Analytics 2 */}
      {process.env.NEXT_PUBLIC_ENV === 'production' && (
        <Script async src="https://www.googletagmanager.com/gtag/js?id=G-1TTPS843H6" />
      )}

      {process.env.NEXT_PUBLIC_ENV === 'production' && (
        <Script
          id="ga-config-2"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-1TTPS843H6');
              `,
          }}
        />
      )}

      {/* Google Analytics 3 */}
      {process.env.NEXT_PUBLIC_ENV === 'production' && (
        <Script async src="https://www.googletagmanager.com/gtag/js?id=AW-16641348589" />
      )}
      {process.env.NEXT_PUBLIC_ENV === 'production' && (
        <Script
          id="ga-config-3"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'AW-16641348589');
              `,
          }}
        />
      )}

      {/* Google Tag Manager */}
      {process.env.NEXT_PUBLIC_ENV === 'production' && (
        <Script id="gtm" strategy="afterInteractive">
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KNJFGRR');
        `}
        </Script>
      )}
      {process.env.NEXT_PUBLIC_ENV === 'production' && (
        <noscript>
          <noscript>
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=GTM-KNJFGRR"
              height="0"
              width="0"
              style={{display: 'none', visibility: 'hidden'}}
            ></iframe>
          </noscript>
        </noscript>
      )}

      {/* Meta Pixel Code  */}
      {process.env.NEXT_PUBLIC_ENV === 'production' && (
        <Script
          id="facebook-pixel"
          dangerouslySetInnerHTML={{
            __html: `!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function()
              {n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)};
               if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);
                t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0];
                 s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', '192614703531560'); 
                  fbq('track', 'PageView');`,
          }}
        />
      )}

      {process.env.NEXT_PUBLIC_ENV === 'production' && (
        <noscript>
          <img
            height="1"
            width="1"
            style={{display: 'none'}}
            src="https://www.facebook.com/tr?id=192614703531560&ev=PageView&noscript=1"
            alt="Meta Pixel Code"
          />
        </noscript>
      )}

      {/* TikTok Code */}
      {process.env.NEXT_PUBLIC_ENV === 'production' && (
        <Script
          id="tiktok-script"
          dangerouslySetInnerHTML={{
            __html: `
          !function (w, d, t) { w.TiktokAnalyticsObject=t;
           var ttq=w[t]=w[t]||[];
           ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],
           ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};
           for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);
           ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);
             return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";
             ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};
             var o=document.createElement("script");
             o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;
             var a=document.getElementsByTagName("script")[0];
             a.parentNode.insertBefore(o,a)}; 
             ttq.load('CIR1E6RC77UF46MML0UG'); 
             ttq.page(); }(window, document, 'ttq');
       `,
          }}
        />
      )}

      {/* Marker.io scrip - Only for UAT */}

      {process.env.NEXT_PUBLIC_ENV === 'staging' && (
        <Script
          id="marker-config"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              (window.markerConfig = {
                destination: '629d62f122488bb20e48afcc',
                source: 'snippet'
              })
            `,
          }}
        />
      )}
      {process.env.NEXT_PUBLIC_ENV === 'staging' && (
        <Script
          id="marker-func"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              (!function(e,r,a){if(!e.__Marker){e.__Marker={};var t=[],n={__cs:t};["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","setCustomData","on","off"].forEach(function(e){n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;var s=r.createElement("script");s.async=1,s.src="https://edge.marker.io/latest/shim.js";var i=r.getElementsByTagName("script")[0];i.parentNode.insertBefore(s,i)}}(window,document))
            `,
          }}
        />
      )}

      {/* Digital River Prebuilt Checkout */}
      <Script src="https://checkout.digitalriverws.com/v1/DigitalRiverCheckout.js" />

      {/* Google Caching */}
      <CacheProvider value={emotionCache}>
        <Head>
          <meta
            name="google-site-verification"
            content="kgF5NwwrDHqzrcYDOpAOtu4s3oY-WoEozL_s9b75gPo"
          />
          <meta name="viewport" content="initial-scale=1, width=device-width, minimal-ui" />
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          {/* <link
            rel="stylesheet"
            href="https://js.digitalriverws.com/v1/css/DigitalRiver.css"
            type="text/css"
          /> */}
        </Head>
        <ThemeProvider theme={theme}>{contentPage}</ThemeProvider>
      </CacheProvider>
    </>
  );
}
export default wrapper.withRedux(MyApp);
